import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, FormGroup, Input, Label, Button, Alert } from 'reactstrap';
import { H5, P } from '../../AbstractElements';
import { createWorkflow, getWebhookPayload } from '../../api/api';
import Action from '../Wapikon/index';

const ShopifyPageContain = () => {
    const [selectedEvent, setSelectedEvent] = useState(''); // Selected Shopify event
    const [webhookUrl, setWebhookUrl] = useState(''); // Webhook URL from the backend
    const [loading, setLoading] = useState(false); // Loading state for the request
    const [events, setEvents] = useState([]); // List of Shopify events
    const [workflowId, setWorkflowId] = useState(''); // Workflow ID after creation
    const [workflowName, setWorkflowName] = useState(''); // Workflow name to be created
    const [showSuccess, setShowSuccess] = useState(false); // Success alert
    const [webhookPayloads, setWebhookPayloads] = useState([]); // State to store webhook payloads
    const [fetchingPayloads, setFetchingPayloads] = useState(false); // Loading state for fetching webhook payloads
    const [noPayloadsMessage, setNoPayloadsMessage] = useState(''); // No payload message

    // Fetch available Shopify events from the backend
    useEffect(() => {
        const availableEvents = [
            { value: 'carts/create', label: 'New Cart' },
            { value: 'orders/create', label: 'New Order' },
            { value: 'products/update', label: 'Product Update' },
            { value: 'customers/create', label: 'New Customer' }
        ];
        setEvents(availableEvents);
    }, []);

    // Handle event selection
    const handleEventChange = (e) => {
        setSelectedEvent(e.target.value);
    };

    // Create Workflow and fetch webhook URL
    const handleCreateWorkflow = async () => {
        if (!workflowName) {
            alert('Please enter a workflow name');
            return;
        }
        if (!selectedEvent) {
            alert('Please select an event');
            return;
        }

        setLoading(true);
        setShowSuccess(false); // Reset success state
        try {
            // Create a new workflow using the API
            const response = await createWorkflow({ name: workflowName });
            const createdWorkflowId = response.data.workflow._id;

            setWorkflowId(createdWorkflowId);

            // Construct the webhook URL dynamically
            const currentDomain = window.location.origin; // This will get the current domain
            const generatedWebhookUrl = `${currentDomain}/api/webhooks/${createdWorkflowId}`;
            setWebhookUrl(generatedWebhookUrl);
            setShowSuccess(true); // Show success message
        } catch (error) {
            console.error('Error creating workflow or fetching the webhook URL:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch webhook payload data
    const handleFetchWebhookResponse = async () => {
        if (!workflowId) {
            alert('No workflow created yet.');
            return;
        }

        setFetchingPayloads(true);
        setNoPayloadsMessage(''); // Reset the message

        try {
            const response = await getWebhookPayload(workflowId);
            if (response.data.payloads.length > 0) {
                setWebhookPayloads(response.data.payloads); // Store the fetched webhook payloads
            } else {
                setNoPayloadsMessage('No webhook data returned. Please try again later.');
            }
        } catch (error) {
            console.error('Error fetching webhook response:', error);
        } finally {
            setFetchingPayloads(false);
        }
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <div className="d-flex justify-content-between align-items-center">
                                    <H5>Shopify Webhook Setup</H5>
                                </div>
                                <P>Select an event and configure the webhook URL for Shopify</P>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        {/* Workflow Name */}
                                        <FormGroup>
                                            <Label for="workflowName">Workflow Name</Label>
                                            <Input
                                                type="text"
                                                id="workflowName"
                                                placeholder="Enter workflow name"
                                                value={workflowName}
                                                onChange={(e) => setWorkflowName(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm="6">
                                        {/* Event Selection */}
                                        <FormGroup>
                                            <Label for="shopifyEvent">Choose Shopify Event</Label>
                                            <Input
                                                type="select"
                                                id="shopifyEvent"
                                                onChange={handleEventChange}
                                                value={selectedEvent}
                                            >
                                                <option value="" disabled>Select an event</option>
                                                {events.map((event, index) => (
                                                    <option key={index} value={event.value}>
                                                        {event.label}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <div className="d-flex justify-content-end">
                                    <Button color="primary" onClick={handleCreateWorkflow} disabled={loading}>
                                        {loading ? 'Creating...' : 'Create Workflow'}
                                    </Button>
                                </div>

                                {/* Show success message */}
                                {showSuccess && (
                                    <Alert color="success" className="mt-4">
                                        Workflow created successfully! Here is your webhook URL:
                                    </Alert>
                                )}

                                {/* Display webhook URL and buttons */}
                                {webhookUrl && (
                                    <div className="mt-4">
                                        <FormGroup>
                                            <Label for="webhookUrl">Webhook URL</Label>
                                            <Input
                                                type="text"
                                                id="webhookUrl"
                                                value={webhookUrl}
                                                readOnly
                                            />
                                        </FormGroup>

                                        <div className="d-flex justify-content-start mt-2">
                                            <Button
                                                color="secondary"
                                                onClick={() => navigator.clipboard.writeText(webhookUrl)}
                                            >
                                                Copy Webhook URL
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                {/* Instructions for setting up the webhook */}
                                {webhookUrl && (
                                    <div className="mt-4">
                                        <H5>How to set up the webhook in Shopify:</H5>
                                        <P>
                                            1. Log in to your Shopify account as an admin.<br />
                                            2. Navigate to <strong>Settings</strong> and scroll to <strong>Notifications</strong>.<br />
                                            3. In the Webhooks section, click <strong>Create Webhook</strong>.<br />
                                            4. Select <strong>{selectedEvent}</strong> as the event type, choose <strong>JSON</strong> as the format, and paste the copied webhook URL.<br />
                                            5. Save the webhook and test it to ensure it's working properly.
                                        </P>
                                    </div>
                                )}

                                {/* No Payload Message */}
                                {noPayloadsMessage && (
                                    <Alert color="warning" className="mt-4">
                                        {noPayloadsMessage}
                                    </Alert>
                                )}

                                {/* Display webhook payloads */}
                                {webhookPayloads.length > 0 && (
                                    <div className="mt-4">
                                        <H5>Received Webhook Payloads:</H5>
                                        {webhookPayloads.map((payload, index) => (
                                            <Card key={index} className="mt-3">
                                                <CardBody>
                                                    <h6>Payload {index + 1}:</h6>
                                                    <pre>{JSON.stringify(payload.payload, null, 2)}</pre>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </div>
                                )}
                            </CardBody>
                            <Action workflowId={workflowId} />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ShopifyPageContain;
