import { H5, P } from '../../AbstractElements';
import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { fetchWorkflowsByUser, deleteWorkflow } from '../../api/api'; // Import the delete API function

const DashboardPageContain = () => {
  const [workflows, setWorkflows] = useState([]); // State to hold the workflows
  const [loading, setLoading] = useState(true);   // Loading state
  const [error, setError] = useState('');         // Error state
  const [modal, setModal] = useState(false);      // State for controlling the modal
  const [selectedApp, setSelectedApp] = useState(''); // State to store selected app
  const [deleting, setDeleting] = useState(false); // State for handling deletion
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false); // Confirmation modal state
  const [workflowToDelete, setWorkflowToDelete] = useState(null); // Store workflow ID to delete
  const [deleteError, setDeleteError] = useState(''); // State for handling delete errors

  const navigate = useNavigate(); // Get navigate for navigation

  // Toggle modal visibility
  const toggleModal = () => setModal(!modal);

  // Toggle delete confirmation modal
  const toggleDeleteModal = () => {
    setConfirmDeleteModal(!confirmDeleteModal);
    setDeleteError(''); // Reset error when toggling modal
  };

  // Handle app selection
  const handleAppSelection = (app) => {
    setSelectedApp(app);
  };

  // Confirm app selection and proceed
  const appLinks = {
    shopify: '/shopify',
    woocommerce: '/woocommerce',
    // Add more apps and their links here if needed
  };

  const handleConfirmAppSelection = () => {
    const link = appLinks[selectedApp];
    if (link) {
      navigate(link);
    }
    toggleModal(); // Close the modal after selection
  };

  // Example function to handle viewing the details of a workflow
  const handleViewWorkflow = (workflowId) => {
    // Redirect or navigate to a detailed workflow page
    console.log('Viewing workflow:', workflowId);
    navigate(`/workflow/${workflowId}`); // Navigate to the workflow details page
  };

  // Handle delete workflow request
  const handleDeleteWorkflow = async (workflowId) => {
    setDeleting(true);
    setDeleteError('');
    try {
      await deleteWorkflow(workflowId);
      setWorkflows(workflows.filter((workflow) => workflow._id !== workflowId)); // Remove from UI
      setDeleting(false);
      toggleDeleteModal(); // Close delete confirmation modal
    } catch (error) {
      console.error('Error deleting workflow:', error);
      setDeleteError('Failed to delete workflow. Please try again.');
      setDeleting(false);
    }
  };

  // Open delete confirmation modal
  const confirmDeleteWorkflow = (workflowId) => {
    setWorkflowToDelete(workflowId);
    toggleDeleteModal();
  };

  // Fetch workflows when the component mounts
  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        setLoading(true);  // Start loading
        const response = await fetchWorkflowsByUser(); // Fetch workflows using the new API function
        setWorkflows(response.data.workflows); // Set workflows from the response
        setLoading(false);  // Stop loading
      } catch (err) {
        console.error('Error fetching workflows:', err);
        setError('Failed to load workflows');
        setLoading(false);
      }
    };

    fetchWorkflows();
  }, []);

  const handleButtonClick = (pageName) => {
    navigate(`/${pageName}`);
    // handleAppSelection(pageName);
    // handleConfirmAppSelection();
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Dashboard</H5>
                <span>Overview of your created workflows</span>
                <Button color="success" className="mt-3" onClick={toggleModal}>
                  Create New Workflow
                </Button>
              </CardHeader>
              <CardBody>
                {loading && <P>Loading workflows...</P>}
                {error && <P className="text-danger">{error}</P>}

                {/* Display the workflows */}
                {!loading && workflows.length > 0 ? (
                  workflows.map((workflow) => (
                    <Card key={workflow._id} className="mb-3">
                      <CardHeader className="d-flex justify-content-between">
                        <H5>{workflow.name}</H5>
                        <div>
                          <Button color="primary" onClick={() => handleViewWorkflow(workflow._id)}>
                            View Details
                          </Button>
                          <Button
                            color="danger"
                            className="ms-2"
                            onClick={() => confirmDeleteWorkflow(workflow._id)}
                            disabled={deleting} // Disable button while deleting
                          >
                            {deleting ? 'Deleting...' : 'Delete'}
                          </Button>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <P>Created on: {new Date(workflow.createdAt).toLocaleDateString()}</P>
                        <P>Status: {workflow.status}</P>
                      </CardBody>
                    </Card>
                  ))
                ) : (
                  !loading && <P>No workflows found.</P>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Modal for selecting an app (Shopify/WooCommerce) */}
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Select an App for Your Workflow</ModalHeader>
          <ModalBody>
            <Button
              color={selectedApp === 'shopify' ? 'primary' : 'secondary'}
              onClick={() => { handleButtonClick('shopify') }}
              className="me-2"
            >
              Shopify
            </Button>
            <Button
              color={selectedApp === 'woocommerce' ? 'primary' : 'secondary'}
              onClick={() => { handleButtonClick('woocommerce') }}
            >
              WooCommerce
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        {/* Confirmation Modal for Deletion */}
        <Modal isOpen={confirmDeleteModal} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>Delete Workflow</ModalHeader>
          <ModalBody>
            {deleteError && <Alert color="danger">{deleteError}</Alert>}
            Are you sure you want to delete this workflow?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => handleDeleteWorkflow(workflowToDelete)} disabled={deleting}>
              {deleting ? 'Deleting...' : 'Delete'}
            </Button>
            <Button color="secondary" onClick={toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default DashboardPageContain;
