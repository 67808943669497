import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import WorkflowDetailPageContain from '../../Component/WorkflowDetailPage';

const WorkflowDetailPage = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Pages" title="Workflow Details" />
            <WorkflowDetailPageContain />
        </Fragment>
    );
};
export default WorkflowDetailPage;