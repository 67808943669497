import React, { Fragment, useState, useEffect } from 'react';
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { EmailAddress, SignIn, Password } from '../../../Constant';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import man from '../../../assets/images/dashboard/1.png';
import FormHeader from './FormHeader';
import SignInWith from './SignInWith';
import { register } from '../../../api/api'; // Import the register API function

const RegisterTab = ({ selected }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const navigate = useNavigate();

    const [value, setValue] = useState(localStorage.getItem('profileURL') || man);

    useEffect(() => {
        localStorage.setItem('profileURL', value);
        localStorage.setItem('Name', name);
    }, [value, name]);

    // Updated register function to use custom API
    const registerAuth = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Call the register API function from your api.js
            const response = await register(name, email, password);

            // Store the JWT token in localStorage
            localStorage.setItem('authToken', response.data.token);
            toast.success('Registration successful!');

            setValue(man);
            setName('');

            // Redirect to the dashboard after successful registration
            setTimeout(() => {
                navigate(`${process.env.PUBLIC_URL}/dashboard`);
            }, 200);
        } catch (error) {
            console.log("Error", error.message);
            // Display error message using toast
            toast.error(error.response?.data?.message || 'Registration failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Form className="theme-form login-form" onSubmit={registerAuth}>
                <FormHeader selected={selected} />
                <FormGroup>
                    <Label>Name</Label>
                    <InputGroup>
                        <InputGroupText><i className='icon-user'></i></InputGroupText>
                        <Input className="form-control" type="text" required="" onChange={e => setName(e.target.value)} value={name} placeholder="Enter your name" />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label>{EmailAddress}</Label>
                    <InputGroup>
                        <InputGroupText><i className='icon-email'></i></InputGroupText>
                        <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} value={email} placeholder="Enter your email" />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label>{Password}</Label>
                    <InputGroup>
                        <InputGroupText><i className='icon-lock'></i></InputGroupText>
                        <Input className="form-control" type={togglePassword ? 'text' : 'password'} onChange={e => setPassword(e.target.value)} value={password} placeholder="Enter your password" required="" />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                            <span className={togglePassword ? '' : 'show'}></span>
                        </div>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Btn attrBtn={{ color: 'primary', className: 'btn-block', disabled: loading }}>
                        {loading ? 'LOADING...' : "Sign Up"}
                    </Btn>
                </FormGroup>
                {/* <SignInWith /> */}
            </Form>
        </Fragment>
    );
};

export default RegisterTab;
