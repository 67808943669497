import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import ShopifyPageContain from '../../Component/ShopifyPage';

const ShopifyPage = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Pages" title="Shopify" />
            <ShopifyPageContain />
        </Fragment>
    );
};
export default ShopifyPage;