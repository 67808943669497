import DashboardPage from '../Pages/DashboardPage';
import WorkflowDetailPage from '../Pages/WorkflowDetailPage'; // The new page
import ShopifyPage from '../Pages/ShopifyPage';
import WooCommercePage from '../Pages/WooCommercePage';

export const routes = [

    {
        path: `/dashboard`, Component: <DashboardPage />
    },
    {
        path: `/shopify`, Component: <ShopifyPage />
    },
    {
        path: `/workflow/:id`, Component: <WorkflowDetailPage />
    },
    {
        path: `/woocommerce`, Component: <WooCommercePage />
    },
];