import { HomeSvg, BagSvgIcon, CartBoxSvg } from '../../Data/svgIcons';

export const MENUITEMS = [
    {
        menutitle: 'DashBoard',
        Items: [
            {
                title: 'DashBoard', icon: HomeSvg, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard`
            },
        ]
    },
    {
        menutitle: 'Shopify',
        Items: [
            {
                title: 'Shopify', icon: BagSvgIcon, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/shopify`
            },
        ]
    },
    {
        menutitle: 'WooCommerce',
        Items: [
            {
                title: 'WooCommerce', icon: CartBoxSvg, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/woocommerce`
            },
        ]
    },
];
