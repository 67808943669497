import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseURL = process.env.REACT_APP_API_URL || 'api';
console.log("baseURL", baseURL);

// Set the base URL for your API
const API = axios.create({
  baseURL: baseURL,
});

// Add the JWT token to the headers for authenticated requests
API.interceptors.request.use((req) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

// Intercept responses to handle 401 errors (Unauthorized)
API.interceptors.response.use(
  (response) => {
    // Return response if everything is fine
    return response;
  },
  (error) => {
    // Check if the error is 401 (Unauthorized)
    if (error.response && error.response.status === 401) {
      // Remove the invalid token from localStorage
      localStorage.removeItem('authToken');

      // Redirect user to login page (using `window.location.href` for a full redirect)
      window.location.href = '/login';
    }

    // Return any other error for further handling
    return Promise.reject(error);
  }
);

// --------- Workflows API ---------

// Get all workflows for the authenticated user
export const fetchWorkflowsByUser = () => API.get('/workflows/user-workflows');

// Create a new workflow
export const createWorkflow = (workflowData) => API.post('/workflows/create', workflowData);

// Create a new workflow action
export const createWorkflowAction = (actionData) => API.post('/workflows/action', actionData);

// Update an existing workflow action
export const updateWorkflowAction = (actionId, updatedData) => API.put(`/workflows/action/${actionId}`, updatedData);

// Delete an existing workflow action
export const deleteWorkflowAction = (actionId) => API.delete(`/workflows/action/${actionId}`);

// Fetch a specific workflow action by its ID
export const getWorkflowActionById = (actionId) => API.get(`/workflows/action/${actionId}`);

// Delete a workflow by ID
export const deleteWorkflow = (workflowId) => API.delete(`/workflows/${workflowId}`);

// Get all actions for a specific workflow
export const getWorkflowActionsByWorkflowId = (workflowId) => API.get(`/workflows/workflow/${workflowId}/actions`);

// Get Payload Data
export const getWebhookPayload = (workflowId) => API.get(`/webhooks/${workflowId}`);

// --------- Authentication API ---------

// Login with email and password
export const login = (email, password) => API.post('/auth/login', { email, password });

export const register = (name, email, password) => API.post('/auth/register', { name, email, password });

// Forgot Password (Admin)
export const forgotPasswordAdmin = (email) => API.post('/auth/forgot-password', { email });

// Sign up with name, email, and password
export const signup = (name, email, password) => API.post('/auth/register', { name, email, password });

// Handle Social Login (Optional)
export const handleSocialLogin = (provider) => {
  // Social login is handled via redirects to Google or Facebook
  window.location.href = `${baseURL}/auth/${provider}`;
};

// --------- WhatsApp API ---------

// Fetch WhatsApp templates with API key
export const fetchWhatsAppTemplates = (apiKey) => {
  const options = {};
  return API.get(`https://app.wapikon.com/api/v1/templates?token=${apiKey}`, options);
};

// Create Request Body for templates with API key
export const getTemplateRequestBody = (templateName, apiKey) => {
  const options = {};
  return API.get(`https://app.wapikon.com/api/v1/generate_template_request/?templateName=${templateName}&token=${apiKey}`, options);
};

export default API;
