import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, FormGroup, Label, Button, Alert, Input } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import { fetchWhatsAppTemplates, getTemplateRequestBody, getWebhookPayload, createWorkflowAction, updateWorkflowAction, getWorkflowActionsByWorkflowId } from '../../api/api';
import CreatableSelect from 'react-select/creatable'; // Import react-select with creatable feature

const flattenObject = (obj, prefix = '', depth = 0) => {
    if (depth > 5) return obj;

    return Object.keys(obj).reduce((acc, k) => {
        const pre = prefix.length ? `${prefix}.` : '';

        if (Array.isArray(obj[k])) {
            obj[k].forEach((item, index) => {
                if (typeof item === 'object' && item !== null) {
                    Object.assign(acc, flattenObject(item, `${pre}${k}[${index}]`, depth + 1));
                } else {
                    acc[`${pre}${k}[${index}]`] = item;
                }
            });
        } else if (typeof obj[k] === 'object' && obj[k] !== null) {
            Object.assign(acc, flattenObject(obj[k], `${pre}${k}`, depth + 1));
        } else {
            acc[`${pre}${k}`] = obj[k];
        }

        return acc;
    }, {});
};

const ActionComponent = ({ workflowId }) => {
    const [selectedApp, setSelectedApp] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateRequestBody, setTemplateRequestBody] = useState(null);
    const [buttons, setButtons] = useState([]);
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [loadingTemplateRequest, setLoadingTemplateRequest] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showApiKeyInput, setShowApiKeyInput] = useState(false);
    const [webhookPayload, setWebhookPayload] = useState(null);
    const [flattenedPayload, setFlattenedPayload] = useState({});
    const [mappings, setMappings] = useState([]);
    const [actionId, setActionId] = useState(null);
    const [webhookUrl, setWebhookUrl] = useState('');

    const apps = [
        { value: 'wapikon', label: 'Wapikon' },
    ];

    useEffect(() => {
        if (workflowId) {
            fetchWebhookData();
            checkForExistingAction();
        }
    }, [workflowId]);

    useEffect(() => {
        if (workflowId) {
            setWebhookUrl(`${window.location.origin}/api/webhooks/${workflowId}`);
        }
    }, [workflowId]);


    const checkForExistingAction = async () => {
        try {
            const response = await getWorkflowActionsByWorkflowId(workflowId);
            if (response.data.action) {
                setActionId(response.data.action._id);
                setSelectedTemplate(response.data.action.templateName);
                setApiKey(response.data.action.apiKey);
                setMappings(response.data.action.mappings);
            }
        } catch (error) {
            console.error("Error checking for existing action:", error);
        }
    };

    const fetchWebhookData = async () => {
        try {
            const response = await getWebhookPayload(workflowId);
            if (response.data.payloads.length > 0) {
                const latestPayload = response.data.payloads[0].payload;
                setWebhookPayload(latestPayload);
                setFlattenedPayload(flattenObject(latestPayload));
                setErrorMessage('');
                setSuccessMessage('Webhook data fetched successfully.');
            } else {
                setErrorMessage('No webhook payload found.');
                setSuccessMessage('');
            }
        } catch (error) {
            console.error('Error fetching webhook payload:', error);
            setErrorMessage('Failed to fetch webhook payload.');
            setSuccessMessage('');
        }
    };

    const handleAppChange = (e) => {
        setSelectedApp(e.target.value);
        setShowApiKeyInput(true);
        setTemplates([]);
        setSelectedTemplate('');
        setErrorMessage('');
        setTemplateRequestBody(null);
    };

    const handleApiKeyChange = (e) => {
        setApiKey(e.target.value);
    };

    const fetchTemplates = async () => {
        if (!apiKey) {
            setErrorMessage('Please enter a valid API key');
            return;
        }

        setLoadingTemplates(true);
        setErrorMessage('');
        try {
            const response = await fetchWhatsAppTemplates(apiKey);
            if (response.data.success) {
                setTemplates(response.data.response);
            } else {
                throw new Error('Failed to fetch templates');
            }
        } catch (error) {
            console.error('Error fetching templates:', error);
            setErrorMessage('Failed to fetch templates. Please check your API key.');
        } finally {
            setLoadingTemplates(false);
        }
    };

    const handleTemplateChange = async (e) => {
        const selectedTemplateName = e.target.value;
        setSelectedTemplate(selectedTemplateName);

        setLoadingTemplateRequest(true);
        try {
            const response = await getTemplateRequestBody(selectedTemplateName, apiKey);
            if (response.data.success) {
                response.data.requestBody.bodyVariables.push('sendTo');
                setTemplateRequestBody(response.data.requestBody);

                const initialMappings = response.data.requestBody.bodyVariables.map(() => []);
                setMappings(initialMappings);
                setButtons([]);
            } else {
                throw new Error('Failed to fetch request body');
            }
        } catch (error) {
            console.error('Error fetching template request body:', error);
            setErrorMessage('Failed to fetch template request body.');
        } finally {
            setLoadingTemplateRequest(false);
        }
    };

    const handleMappingChange = (index, selectedOptions) => {
        const selectedValues = selectedOptions.map(option => ({
            value: option.value,
            type: option.__isNew__ ? 'manual' : 'dropdown'
        }));
        const newMappings = [...mappings];
        newMappings[index] = selectedValues;
        setMappings(newMappings);
    };

    const handleButtonChange = (index, selectedOptions) => {
        const selectedValues = selectedOptions.map(option => ({
            value: option.value,
            type: option.__isNew__ ? 'manual' : 'dropdown'
        }));
        const newButtons = [...buttons];
        newButtons[index] = selectedValues.flat();
        setButtons(newButtons);
    };

    const handleSubmit = async () => {
        if (!selectedTemplate) {
            alert('Please select a WhatsApp template to proceed.');
            return;
        }

        const requestData = {
            workflowId,
            actionName: 'Send WhatsApp Message',
            mappings: mappings.map((mapping, index) => ({
                variable: templateRequestBody.bodyVariables[index],
                value: mapping.map(item => item.value).join(', '),
                type: mapping.map(item => item.type).join(', ')
            })),
            buttons: buttons.flat(),
            templateName: selectedTemplate,
            apiKey,
        };

        try {
            if (actionId) {
                const response = await updateWorkflowAction(actionId, requestData);
                console.log('Action updated:', response);
                alert('Action updated successfully!');
            } else {
                const response = await createWorkflowAction(requestData);
                setActionId(response.data.action._id);
                alert('Action created successfully!');
            }
        } catch (error) {
            console.error('Error submitting action:', error);
            alert('Failed to submit action.');
        }
    };

    // Test action to make a POST request to the webhook URL
    const handleTestAction = async () => {
        try {
            const response = await fetch(webhookUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(webhookPayload), // Send webhook payload
            });
            if (response.ok) {
                alert('Test action executed successfully!');
            } else {
                alert('Failed to execute test action.');
            }
        } catch (error) {
            console.error('Error executing test action:', error);
            alert('Error executing test action.');
        }
    };

    const dropdownOptions = Object.keys(flattenedPayload || {}).map((payloadKey) => ({
        value: payloadKey,
        label: `${payloadKey}: ${flattenedPayload[payloadKey]}`,
    }));

    return (
        <Card>
            <CardHeader className="pb-0">
                <H5>Action: Do this...</H5>
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <Label for="selectApp">Choose App</Label>
                    <Input
                        type="select"
                        id="selectApp"
                        value={selectedApp}
                        onChange={handleAppChange}
                    >
                        <option value="" disabled>Select an app</option>
                        {apps.map((app, index) => (
                            <option key={index} value={app.value}>
                                {app.label}
                            </option>
                        ))}
                    </Input>
                </FormGroup>

                {showApiKeyInput && (
                    <>
                        <FormGroup>
                            <Label for="apiKey">Enter API Key</Label>
                            <Input
                                type="text"
                                id="apiKey"
                                value={apiKey}
                                onChange={handleApiKeyChange}
                                placeholder="Enter your API Key"
                            />
                            <Button color="primary" onClick={fetchTemplates} className="mt-2">
                                {loadingTemplates ? 'Fetching Templates...' : 'Fetch WhatsApp Templates'}
                            </Button>
                        </FormGroup>

                        {errorMessage && (
                            <Alert color="danger" className="mt-3">
                                {errorMessage}
                            </Alert>
                        )}

                        {successMessage && (
                            <Alert color="success" className="mt-3">
                                {successMessage}
                            </Alert>
                        )}

                        {templates.length > 0 && (
                            <FormGroup>
                                <Label for="selectTemplate">Select WhatsApp Template</Label>
                                <Input
                                    type="select"
                                    id="selectTemplate"
                                    value={selectedTemplate}
                                    onChange={handleTemplateChange}
                                >
                                    <option value="" disabled>Select a template</option>
                                    {templates.map((template, index) => (
                                        <option key={index} value={template}>
                                            {template}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        )}

                        {loadingTemplateRequest && <p>Loading request body...</p>}

                        {templateRequestBody && (
                            <div className="mt-4">
                                <h5>Request Body for "{selectedTemplate}"</h5>
                                <p>Map the webhook payload to the template variables below:</p>
                                {templateRequestBody.bodyVariables.map((variable, index) => (
                                    <FormGroup key={index}>
                                        <Label>{`Template Variable: ${variable}`}</Label>
                                        <CreatableSelect
                                            isMulti
                                            options={dropdownOptions}
                                            onChange={(selectedOptions) => handleMappingChange(index, selectedOptions)}
                                            placeholder="Select or create values"
                                        />
                                    </FormGroup>
                                ))}
                                <p>Map the webhook payload to the button variables below:</p>
                                {templateRequestBody?.buttons && templateRequestBody?.buttons.map((variable, index) => (
                                    <FormGroup key={index}>
                                        <Label>{`Template Button Variable: ${variable}`}</Label>
                                        <CreatableSelect
                                            isMulti
                                            options={dropdownOptions}
                                            onChange={(selectedOptions) => handleButtonChange(index, selectedOptions)}
                                            placeholder="Select or create values"
                                        />
                                    </FormGroup>
                                ))}
                            </div>
                        )}
                    </>
                )}

                <Button
                    color="primary"
                    className="mt-3"
                    onClick={handleSubmit}
                    disabled={!selectedTemplate}
                >
                    {actionId ? 'Update Action' : 'Create Action'}
                </Button>
                {"  "}
                <Button
                    color="primary"
                    className="mt-3"
                    onClick={handleTestAction}
                    disabled={!webhookPayload}
                >
                    Test Action
                </Button>
                {"  "}
                <Button
                    color="primary"
                    className="mt-3"
                    onClick={fetchWebhookData}
                    disabled={!workflowId}
                >
                    Fetch Webhook Data
                </Button>
            </CardBody>
        </Card>
    );
};

export default ActionComponent;
