import React, { Fragment, useState, useEffect } from 'react';
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { EmailAddress, SignIn, Password } from '../../../Constant';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import man from '../../../assets/images/dashboard/1.png';
import FormHeader from './FormHeader';
import FormPassword from './FormPassword';
import { login } from '../../../api/api'; // Import the login API function

const LoginTab = ({ selected }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const history = useNavigate();

    const [value, setValue] = useState(localStorage.getItem('profileURL') || man);
    const [name, setName] = useState(localStorage.getItem('Name') || '');

    useEffect(() => {
        localStorage.setItem('profileURL', value);
        localStorage.setItem('Name', name);
    }, [value, name]);

    // Updated login function to use custom API
    const loginAuth = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Call the login API function from your api.js
            const response = await login(email, password);

            // Store the JWT token in localStorage
            const message = response.data?.message;
            if (message) {
                toast.info(message);
            }

            localStorage.setItem('authToken', response.data.token);
            setValue(man);
            setName('');

            // Redirect to the dashboard after successful login
            setTimeout(() => {
                history(`${process.env.PUBLIC_URL}/dashboard`);
            }, 200);
        } catch (error) {
            // Handle different error scenarios
            const errorMessage = error.response?.data?.message || 'Login failed. Please try again.';
            console.log("Login error:", errorMessage);

            toast.error(errorMessage);
            // Display error message using toast

            // You can check for specific status codes to display more relevant messages
            if (error.response?.status === 401) {
                toast.error('Invalid credentials. Please check your email and password.');
            } else if (error.response?.status === 500) {
                toast.error('Server error. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Form className="theme-form login-form">
                <FormHeader selected={selected} />
                <FormGroup>
                    <Label>{EmailAddress}</Label>
                    <InputGroup>
                        <InputGroupText><i className='icon-email'></i></InputGroupText>
                        <Input
                            className="form-control"
                            type="email"
                            required
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            placeholder="Enter your email"
                        />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label>{Password}</Label>
                    <InputGroup>
                        <InputGroupText><i className='icon-lock'></i></InputGroupText>
                        <Input
                            className="form-control"
                            type={togglePassword ? 'text' : 'password'}
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            placeholder="Enter your password"
                            required
                        />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                            <span className={togglePassword ? '' : 'show'}></span>
                        </div>
                    </InputGroup>
                </FormGroup>
                <FormPassword />
                <FormGroup>
                    <Btn attrBtn={{ color: 'primary', className: 'btn-block', disabled: loading, onClick: (e) => loginAuth(e) }}>
                        {loading ? 'LOADING...' : SignIn}
                    </Btn>
                </FormGroup>
                {/* <SignInWith /> */}
            </Form>
        </Fragment>
    );
};

export default LoginTab;
