import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getWorkflowActionsByWorkflowId, updateWorkflowAction, deleteWorkflowAction, fetchWhatsAppTemplates, getTemplateRequestBody, getWebhookPayload } from '../../api/api'; // API calls
import { Card, CardBody, CardHeader, Container, Row, Col, Button, Badge, Input, InputGroup, FormGroup, Label } from 'reactstrap';
import CreatableSelect from 'react-select/creatable'; // Dropdown for selecting template
import { toast } from 'react-toastify';

const WorkflowDetailPageContain = () => {
    const { id } = useParams(); // Get the workflow ID from the URL
    const [actions, setActions] = useState([]); // State to hold the workflow actions data
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(''); // Error state
    const [webhookUrl, setWebhookUrl] = useState(''); // Webhook URL
    const [templates, setTemplates] = useState([]); // WhatsApp templates
    const [loadingTemplates, setLoadingTemplates] = useState(false); // Loading state for templates
    const [successMessage, setSuccessMessage] = useState(''); // Success message
    const [errorMessage, setErrorMessage] = useState(''); // Error message for fetching templates
    const [webhookPayload, setWebhookPayload] = useState(null); // Webhook payload data
    const [flattenedPayload, setFlattenedPayload] = useState({}); // Flattened payload for mapping
    const [tempMappings, setTempMappings] = useState({}); // Temporary mappings for each action before saving
    const [tempTemplate, setTempTemplate] = useState({}); // Temporary template for each action before saving
    const [tempApiKey, setTempApiKey] = useState({}); // Temporary API key for each action before saving
    const [templateRequestBody, setTemplateRequestBody] = useState({}); // Body variables for the selected template

    // Fetch the workflow actions when the component mounts
    useEffect(() => {
        const fetchWorkflowDetails = async () => {
            try {
                setLoading(true);
                const response = await getWorkflowActionsByWorkflowId(id); // Fetch workflow actions by workflow ID
                setActions(response.data.actions); // Set the actions array from the response
                await fetchWebhookData(); // Fetch webhook data
                fetchTemplatesFromBackend(response.data.actions); // Fetch templates using the backend API key
                setLoading(false);
            } catch (err) {
                console.error('Error fetching workflow details:', err);
                setError('Failed to load workflow details');
                setLoading(false);
            }
        };

        fetchWorkflowDetails();
    }, [id]);

    
  useEffect(() => {
    console.log("id for webhook",id)
    setWebhookUrl(`${window.location.origin}/api/webhooks/${id}`);
  }, [id]);

    // Fetch Webhook Payload Data for the workflow
    const fetchWebhookData = async () => {
        try {
            const response = await getWebhookPayload(id); // Fetch the webhook payload
            if (response.data.payloads.length > 0) {
                const latestPayload = response.data.payloads[0].payload;
                setWebhookPayload(latestPayload);
                setFlattenedPayload(flattenObject(latestPayload));
                setSuccessMessage('Webhook data fetched successfully.');
            } else {
                setErrorMessage('No webhook payload found.');
            }
        } catch (error) {
            setErrorMessage('Failed to fetch webhook payload.');
        }
    };

    // Optimized function to flatten object and handle arrays
    const flattenObject = (obj, prefix = '', depth = 0) => {
        if (depth > 5) return obj;
        return Object.keys(obj).reduce((acc, k) => {
            const pre = prefix.length ? `${prefix}.` : '';
            if (Array.isArray(obj[k])) {
                obj[k].forEach((item, index) => {
                    if (typeof item === 'object' && item !== null) {
                        Object.assign(acc, flattenObject(item, `${pre}${k}[${index}]`, depth + 1));
                    } else {
                        acc[`${pre}${k}[${index}]`] = item;
                    }
                });
            } else if (typeof obj[k] === 'object' && obj[k] !== null) {
                Object.assign(acc, flattenObject(obj[k], `${pre}${k}`, depth + 1));
            } else {
                acc[`${pre}${k}`] = obj[k];
            }
            return acc;
        }, {});
    };

    // Fetch WhatsApp templates using API key from the backend
    const fetchTemplatesFromBackend = async (actions) => {
        try {
            if (actions && actions.length > 0) {
                const actionWithApiKey = actions[0]; // Assuming the first action has an API key
                const apiKey = actionWithApiKey.apiKey; // Get the API key from backend

                if (apiKey) {
                    setLoadingTemplates(true);
                    setErrorMessage('');
                    const response = await fetchWhatsAppTemplates(apiKey); // Fetch WhatsApp templates with API key
                    if (response.data.success) {
                        setTemplates(response.data.response);
                    } else {
                        throw new Error('Failed to fetch templates');
                    }
                } else {
                    setErrorMessage('No API key found for this workflow.');
                }
            }
        } catch (error) {
            setErrorMessage('Failed to fetch templates. Please check the API key.');
        } finally {
            setLoadingTemplates(false);
        }
    };

    const fetchTemplateBodyVariables = async (templateName, apiKey) => {
        try {
            const response = await getTemplateRequestBody(templateName, apiKey); // Fetch the request body for the template
            if (response.data.success) {
                response.data.requestBody.bodyVariables.push("send To");
                setTemplateRequestBody(response.data.requestBody);
            } else {
                throw new Error('Failed to fetch template body variables');
            }
        } catch (error) {
            console.error('Error fetching template request body:', error);
            setErrorMessage('Failed to fetch template request body.');
        }
    };

    // Handle toggling the action status (active/inactive)
    const handleToggleStatus = async (action) => {
        try {
            const updatedStatus = action.status === 'active' ? 'inactive' : 'active';
            const updatedAction = { ...action, status: updatedStatus };
            await updateWorkflowAction(action._id, updatedAction); // Update the action status in the backend

            setActions((prevActions) =>
                prevActions.map((act) =>
                    act._id === action._id ? { ...act, status: updatedStatus } : act
                )
            );
            toast.success(`Action ${updatedStatus === 'active' ? 'activated' : 'deactivated'} successfully!`);
        } catch (err) {
            console.error('Error updating action status:', err);
            setError('Failed to update action status');
        }
    };

    // Handle deleting an action
    const handleDeleteAction = async (actionId) => {
        try {
            await deleteWorkflowAction(actionId); // Delete the action from the backend

            setActions((prevActions) => prevActions.filter((act) => act._id !== actionId));
            toast.success('Action deleted successfully!');
        } catch (err) {
            console.error('Error deleting action:', err);
            setError('Failed to delete action');
        }
    };

    // Handle template change and fetch body variables
    const handleTemplateChange = async (actionId, newTemplate) => {
        const action = actions.find((act) => act._id === actionId);
        const apiKey = action.apiKey;
        if (newTemplate) {
            setTempTemplate((prev) => ({
                ...prev,
                [actionId]: newTemplate,
            }));
            // Fetch body variables for the new template
            await fetchTemplateBodyVariables(newTemplate, apiKey);
        }
    };

    // Handle API key change
    const handleApiKeyChange = (actionId, newApiKey) => {
        setTempApiKey((prev) => ({
            ...prev,
            [actionId]: newApiKey,
        }));
    };

    // Handle mapping change, but only update tempMappings (not immediately in the backend)
    const handleMappingChange = (actionId, index, selectedOptions) => {
        const selectedValues = selectedOptions.map(option => ({
            value: option.value,
            type: option.__isNew__ ? 'manual' : 'dropdown', // Identify if it's manual input or dropdown
        }));
        setTempMappings((prev) => ({
            ...prev,
            [actionId]: {
                ...(prev[actionId] || {}),
                [index]: selectedValues,
            },
        }));
    };

    // Handle updating the entire action when Save button is clicked
    const handleUpdateAction = async (actionId) => {
        const updatedAction = actions.find(action => action._id === actionId);
        const updatedTemplate = tempTemplate[actionId] || updatedAction.templateName;
        const updatedApiKey = tempApiKey[actionId] || updatedAction.apiKey;
        const updatedMappings = tempMappings[actionId] || [];

        // Convert tempMappings to the expected structure
        const formattedMappings = Object.keys(updatedMappings).map((index) => {
            const mapping = updatedMappings[index];
            return {
                variable: templateRequestBody.bodyVariables[index], // Get the variable name
                value: mapping.map(m => m.value).join(', '), // Concatenate values if multiple
                type: mapping.map(m => m.type).join(', '), // Concatenate types if multiple
            };
        });

        const updatedActionData = {
            ...updatedAction,
            templateName: updatedTemplate,
            apiKey: updatedApiKey, // Include updated API key
            mappings: formattedMappings,
        };

        try {
            await updateWorkflowAction(actionId, updatedActionData); // Update action in backend
            toast.success('Action updated successfully!');

            // Update the action in the local state
            setActions((prevActions) =>
                prevActions.map((action) => (action._id === actionId ? updatedActionData : action))
            );
        } catch (error) {
            setError('Failed to update action.');
        }
    };

    // Copy webhook URL to clipboard
    const copyWebhookUrl = () => {
        navigator.clipboard.writeText(webhookUrl);
        toast.success('Webhook URL copied to clipboard!');
    };

    // Test action to make a POST request to the webhook URL
    const handleTestAction = async () => {
        try {
            const response = await fetch(webhookUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(webhookPayload), // Send webhook payload
            });
            if (response.ok) {
                toast.success('Test action executed successfully!');
            } else {
                toast.error('Failed to execute test action.');
            }
        } catch (error) {
            toast.error('Error executing test action.');
        }
    };

    // Prepare dropdown options from flattenedPayload
    const dropdownOptions = Object.keys(flattenedPayload || {}).map((payloadKey) => ({
        value: payloadKey,
        label: `${payloadKey}: ${flattenedPayload[payloadKey]}`,
    }));

    return (
        <Container fluid={true}>
            <Row>
                <Col sm="12">
                    {loading && <p>Loading workflow details...</p>}
                    {error && <p className="text-danger">{error}</p>}

                    {/* Webhook URL Display and Copy Button */}
                    <Card className="mb-3">
                        <CardHeader className="d-flex justify-content-between align-items-center">
                            <h5>Webhook URL</h5>
                            <Button color="primary" onClick={copyWebhookUrl}>
                                Copy Webhook URL
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <InputGroup>
                                <Input className="form-control" value={webhookUrl} readOnly />
                            </InputGroup>
                        </CardBody>
                    </Card>

                    <Button
                        color="primary"
                        className="mb-3"
                        onClick={handleTestAction}
                        disabled={!webhookPayload}
                    >
                        Test Action
                    </Button>

                    {!loading && actions.length > 0 ? (
                        actions.map((action) => (
                            <Card key={action._id} className="mb-3">
                                <CardHeader className="d-flex justify-content-between align-items-center">
                                    <h5>{action.actionName}</h5>
                                    <div>
                                        <Badge
                                            color={action.status === 'active' ? 'success' : 'secondary'}
                                            className="me-2"
                                        >
                                            {action.status === 'active' ? 'Active' : 'Inactive'}
                                        </Badge>
                                        <Button
                                            color={action.status === 'active' ? 'danger' : 'success'}
                                            onClick={() => handleToggleStatus(action)}
                                            className="me-2"
                                        >
                                            {action.status === 'active' ? 'Deactivate' : 'Activate'}
                                        </Button>
                                        <Button
                                            color="danger"
                                            onClick={() => handleDeleteAction(action._id)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <p><strong>Created At:</strong> {new Date(action.createdAt).toLocaleDateString()}</p>

                                    {/* API Key Input */}
                                    <FormGroup>
                                        <Label for={`apiKey-${action._id}`}><strong>API Key:</strong></Label>
                                        <Input
                                            type="text"
                                            id={`apiKey-${action._id}`}
                                            value={tempApiKey[action._id] || action.apiKey}
                                            onChange={(e) => handleApiKeyChange(action._id, e.target.value)}
                                        />
                                    </FormGroup>

                                    {/* Template Dropdown */}
                                    <FormGroup>
                                        <Label for={`template-${action._id}`}><strong>Template Name:</strong></Label>
                                        <CreatableSelect
                                            id={`template-${action._id}`}
                                            isClearable
                                            value={{
                                                value: tempTemplate[action._id] || action.templateName,
                                                label: tempTemplate[action._id] || action.templateName,
                                            }}
                                            options={templates.map((template) => ({
                                                value: template,
                                                label: template,
                                            }))}
                                            onChange={(selectedOption) =>
                                                handleTemplateChange(action._id, selectedOption?.value || '')
                                            }
                                        />
                                    </FormGroup>

                                    {/* Map Template Variables */}
                                    {templateRequestBody.bodyVariables && (
                                        <>
                                            <h5>Map the webhook payload to template variables</h5>
                                            {templateRequestBody.bodyVariables.map((variable, index) => (
                                                <FormGroup key={index}>
                                                    <Label>{`Template Variable: ${variable}`}</Label>
                                                    <CreatableSelect
                                                        isMulti
                                                        options={dropdownOptions}
                                                        onChange={(selectedOptions) =>
                                                            handleMappingChange(action._id, index, selectedOptions)
                                                        }
                                                        placeholder="Select or create values"
                                                    />
                                                </FormGroup>
                                            ))}
                                            <h5>Map the webhook payload to template variables</h5>
                                            {templateRequestBody?.buttons && templateRequestBody?.buttons.map((variable, index) => (
                                                <FormGroup key={index}>
                                                    <Label>{`Button Variable: ${variable}`}</Label>
                                                    <CreatableSelect
                                                        isMulti
                                                        options={dropdownOptions}
                                                        onChange={(selectedOptions) =>
                                                            handleMappingChange(action._id, index, selectedOptions)
                                                        }
                                                        placeholder="Select or create values"
                                                    />
                                                </FormGroup>
                                            ))}
                                        </>
                                    )}

                                    {/* Update Button */}
                                    <Button
                                        color="primary"
                                        onClick={() => handleUpdateAction(action._id)}
                                        className="mt-2"
                                    >
                                        Save Action
                                    </Button>
                                </CardBody>
                            </Card>
                        ))
                    ) : (
                        !loading && <p>No actions found for this workflow.</p>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default WorkflowDetailPageContain;
