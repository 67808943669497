import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import WooCommercePageContain from '../../Component/WooCommercePage';

const WooCommercePage = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Pages" title="WooCommerce" />
            <WooCommercePageContain />
        </Fragment>
    );
};
export default WooCommercePage;