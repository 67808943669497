import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import DashboardPageContain from '../../Component/DashboardPage';

const DashboardPage = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Pages" title="Dashboard" />
            <DashboardPageContain />
        </Fragment>
    );
};
export default DashboardPage;